import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { InformationCircleIcon } from "@shared/components/icons";
import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { Button } from "@shared/primitives/button";
import { NewModal } from "@shared/primitives/new-modal";
import { Table } from "@shared/primitives/table";
import { ToolTip } from "@shared/primitives/tooltip";
import { WcagStatus } from "@shared/primitives/wcag-status/wcag-status";
import { parseCreditToText } from "@shared/utils/plans";

import type { AccessibilityComplianceStandard } from "../accessibility-report-modal";
import { FlagAndLanguage } from "../components/flag-and-language";

interface AccessibilityReportSummaryViewModalProps {
  onDismiss: () => void;
  onClickAnalyse: () => void;
  onClickDetailedView: () => void;
  disableReAnalyse?: boolean;
  media: EnrichedMediaListItem;
  analysedAt: string;
  complianceStandards: AccessibilityComplianceStandard[];
}

export const AccessibilityReportSummaryViewModal: React.FC<AccessibilityReportSummaryViewModalProps> = (props) => {
  return (
    <NewModal
      title={"Analyse Accessibility for " + props.media.name}
      description="This report analyses the media against WCAG AA standards."
      showCloseButton
      onDismiss={props.onDismiss}
      size="930"
      className="tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
    >
      <span className="tw--mt-1 tw-text-sm tw-text-neutral-500">
        Made on {format(new Date(props.analysedAt), "d MMMM yyyy")}
      </span>
      <div className="tw-mb-8 tw-mt-4 tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
        <h3 className="tw-mb-3 tw-mt-2 tw-text-lg tw-font-medium">Media Overview</h3>
        <InputFilesTable media={props.media} />
        <h3 className="tw-mb-3 tw-text-lg tw-font-medium">Compliance Overview</h3>
        <ComplianceOverviewTable standards={props.complianceStandards} />
      </div>
      <div className="tw-flex tw-items-center tw-justify-end tw-gap-3">
        <Button variant="secondary" className="tw-mr-auto" size="32" onClick={props.onDismiss}>
          Close
        </Button>
        <Button variant="secondary" size="32" onClick={props.onClickAnalyse} disabled={props.disableReAnalyse}>
          Re-Analyse
        </Button>
        <Button variant="primary" size="32" onClick={props.onClickDetailedView}>
          View Detailed Issues
        </Button>
      </div>
    </NewModal>
  );
};

type InputFilesTable = {
  media: EnrichedMediaListItem;
};

export const InputFilesTable: React.FC<InputFilesTable> = (props) => {
  const [length] = React.useMemo(() => {
    const length = parseCreditToText(props.media.duration ?? 0);
    return [length];
  }, [props.media.languageCode, props.media.duration]);

  return (
    <Table
      className="tw-mb-6 tw-flex tw-w-full tw-overflow-auto"
      columns={[
        { id: "file-name", label: "Media name", width: "auto" },
        { id: "file-duration", label: "Duration", width: "auto" },
        { id: "spoken-language", label: "Spoken language", width: "auto" }
      ]}
      items={[]}
    >
      <Table.Header className="tw-sticky tw-top-0 tw--mb-px !tw-h-9 !tw-min-h-9 tw-overflow-hidden tw-whitespace-nowrap tw-rounded-t-7 tw-border-b tw-border-neutral-200 tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => (
            <Table.HeaderCell key={col.id} columnId={col.id} className="!tw-h-9 !tw-min-h-9">
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      <Table.Row className={classNames("!tw-h-auto !tw-min-h-[68px] tw-rounded-b-7")}>
        <Table.Cell columnId="file-name" className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0">
          {props.media.name}
        </Table.Cell>
        <Table.Cell
          columnId="file-duration"
          className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0"
        >
          {length}
        </Table.Cell>
        <Table.Cell
          columnId="spoken-language"
          className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0"
        >
          <FlagAndLanguage languageCode={props.media.languageCode} />
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};

type ComplianceOverviewTableProps = {
  standards: AccessibilityComplianceStandard[];
};

enum ComplianceOverviewColumnId {
  MEDIA = "media",
  STANDARD = "standard",
  STATUS = "status"
}

export const ComplianceOverviewTable: React.FC<ComplianceOverviewTableProps> = (props) => {
  return (
    <Table
      className="tw-flex tw-w-full tw-overflow-auto"
      columns={[
        { id: ComplianceOverviewColumnId.MEDIA, label: "Media", width: "auto" },
        { id: ComplianceOverviewColumnId.STANDARD, label: "Compliance Framework", width: "auto" },
        { id: ComplianceOverviewColumnId.STATUS, label: "Status", width: "auto" }
      ]}
      items={[]}
    >
      <Table.Header className="tw-sticky tw-top-0 tw--mb-px !tw-h-12 !tw-min-h-12 tw-overflow-hidden tw-whitespace-nowrap tw-rounded-t-7 tw-border-b tw-border-neutral-200 tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => (
            <Table.HeaderCell key={col.id} columnId={col.id}>
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      {props.standards.map((standard, i) => {
        return (
          <Table.Row
            key={i}
            className={classNames("!tw-h-auto !tw-min-h-[68px]", {
              "tw-rounded-b-7": props.standards.length - 1 === i
            })}
          >
            <Table.Cell
              columnId={ComplianceOverviewColumnId.MEDIA}
              className="tw-flex tw-flex-row tw-items-center !tw-justify-start !tw-gap-2"
            >
              <h4 className="tw-text-sm tw-text-neutral-900">{standard.label}</h4>
              <ToolTip text={standard.tooltipText} place="top">
                <InformationCircleIcon className="tw-h-4 tw-w-4 tw-cursor-pointer tw-stroke-neutral-700" />
              </ToolTip>
            </Table.Cell>
            <Table.Cell columnId={ComplianceOverviewColumnId.STANDARD}>
              {standard.framework ? standard.framework : "-"}
            </Table.Cell>
            <Table.Cell columnId={ComplianceOverviewColumnId.STATUS}>
              {standard.level ? <WcagStatus wcagLevel={standard.level} /> : "-"}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};
