import { AccessibilityCriteria, AssessmentStatus, CaptionsType, CombinedReport, WCAGLevel } from "@getsubly/common";

export const sampleReport: CombinedReport = {
  captionReports: [
    {
      [AccessibilityCriteria.CAPTION_CONTRAST]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Increase contrast to at least 4.5:1 for all caption text",
        WCAGIssues: [
          {
            start: 0,
            end: 12000,
            remediation: "Change text color to white and add a dark background or outline",
            contrastRatio: 3.8
          }
        ]
      },
      [AccessibilityCriteria.CPS]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Reduce the caption speed to 25 CPS or below",
        WCAGIssues: [
          {
            start: 0,
            end: 12000,
            remediation: "Split caption into multiple segments",
            cps: 28
          }
        ]
      },
      [AccessibilityCriteria.CPL]: {
        WCAGLevel: WCAGLevel.PASS_AA,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "",
        WCAGIssues: []
      },
      captionsType: CaptionsType.CLOSED,
      languageCode: "en-GB",
      WCAGCaptionCompliance: WCAGLevel.PASS_AAA
    },
    // Spanish caption report
    {
      [AccessibilityCriteria.CAPTION_CONTRAST]: {
        WCAGLevel: WCAGLevel.PASS_AA,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "",
        WCAGIssues: []
      },
      [AccessibilityCriteria.CPS]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Reduce the caption speed to 25 CPS or below",
        WCAGIssues: [
          {
            start: 15000,
            end: 25000,
            remediation: "Split caption into multiple segments",
            cps: 30
          },
          {
            start: 45000,
            end: 52000,
            remediation: "Simplify text to reduce reading speed",
            cps: 32
          },
          {
            start: 68000,
            end: 72000,
            remediation: "Extend caption duration",
            cps: 29
          }
        ]
      },
      [AccessibilityCriteria.CPL]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Reduce caption length to 50 characters or less per line",
        WCAGIssues: [
          {
            start: 30000,
            end: 40000,
            remediation: "Split text into multiple lines",
            cpl: 65
          },
          {
            start: 78000,
            end: 85000,
            remediation: "Summarise content to reduce text length",
            cpl: 58
          }
        ]
      },
      captionsType: CaptionsType.CLOSED,
      languageCode: "es",
      WCAGCaptionCompliance: WCAGLevel.PASS_AA
    },
    // French caption report
    {
      [AccessibilityCriteria.CAPTION_CONTRAST]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Increase contrast to at least 4.5:1 for all caption text",
        WCAGIssues: [
          {
            start: 50000,
            end: 60000,
            remediation: "Change text color to white and add a dark background or outline",
            contrastRatio: 2.9
          },
          {
            start: 75000,
            end: 90000,
            remediation: "Add thicker outline to text for improved readability",
            contrastRatio: 3.2
          },
          {
            start: 100000,
            end: 115000,
            remediation: "Use more readable font and semi-transparent background",
            contrastRatio: 3.7
          }
        ]
      },
      [AccessibilityCriteria.CPS]: {
        WCAGLevel: WCAGLevel.FAIL,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "Reduce caption speed to 25 CPS or below",
        WCAGIssues: [
          {
            start: 22000,
            end: 28000,
            remediation: "Split text into multiple captions",
            cps: 27
          },
          {
            start: 95000,
            end: 105000,
            remediation: "Simplify dialogue to reduce word count",
            cps: 31
          }
        ]
      },
      [AccessibilityCriteria.CPL]: {
        WCAGLevel: WCAGLevel.PASS_AAA,
        assessmentStatus: AssessmentStatus.ASSESSED,
        remediation: "",
        WCAGIssues: []
      },
      captionsType: CaptionsType.CLOSED,
      languageCode: "fr",
      WCAGCaptionCompliance: WCAGLevel.PASS_A
    }
  ],
  [AccessibilityCriteria.FLASHING_CONTENT]: {
    WCAGLevel: WCAGLevel.FAIL,
    assessmentStatus: AssessmentStatus.ASSESSED,
    remediation: "Reduce flashing content to fewer than 3 flashes per second",
    WCAGIssues: [
      {
        start: 45000,
        end: 60000,
        remediation: "Edit the sequence to slow down the flash rate",
        flashes: 5,
        containsRed: true
      }
    ]
  },
  [AccessibilityCriteria.FONT_RECOGNITION]: {
    WCAGLevel: WCAGLevel.FAIL,
    assessmentStatus: AssessmentStatus.ASSESSED,
    remediation: "Increase font size to at least 18pt or 14pt bold",
    WCAGIssues: [
      {
        start: 0,
        end: 120000,
        remediation: "Increase caption font size",
        fontSize: 16
      }
    ]
  },
  WCAGVideoCompliance: WCAGLevel.FAIL,
  WCAGPackageCompliance: WCAGLevel.FAIL
};
